.arrayedit-overlay {
    position: fixed;
    top: 0; left: 0;
    width: 100vw; height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.arrayedit-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    min-width: 300px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.arrayedit-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.arrayedit-input {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
    font-size: 1rem;
}

.arrayedit-remove-btn {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 30px;
    height: 30px;

    /* Center the X vertically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrayedit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px; /* Add spacing between buttons */
    margin-top: 15px;
}

.arrayedit-buttons button {
    padding: 8px 12px;
    font-size: 1rem;
    cursor: pointer;
}
